import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'button', 'destroy'];

    empty() {
        if (this.buttonTarget.classList.contains('in')) {
            this.inputTarget.value = '';
            this.showHideButton();
            this.destroyTarget.value = '1';
        }
    }

    showHideButton() {
        if (this.inputTarget.value == '') {
            this.buttonTarget.classList.add('hide');
            this.buttonTarget.classList.remove('in');
        } else {
            this.buttonTarget.classList.add('in');
            this.buttonTarget.classList.remove('hide');
            this.destroyTarget.value = '0';
        }
    }
}
