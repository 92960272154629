import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

export default class extends Controller {
    static targets = ['star'];

    connect() {
        let stop = this.data.get('stop');
        let admin = this.data.get('admin');
        if (admin === 'true') {
            return;
        }
        let path = '/releases/star_check';
        let that = this;
        axios(path, {
            method: 'GET',
            params: {
                stop: stop,
            },
        }).then(function (json) {
            if (json.data.result) {
                that.starTarget.classList.add('in');
                that.starTarget.classList.remove('hide');
                that.starTarget.parentNode.title =
                    "<h5 style='color:white;'>New version released!</h5>";
                $(that.starTarget.parentNode).tooltip({
                    html: true,
                    placement: 'right',
                });
            } else {
                that.starTarget.classList.add('hide');
                that.starTarget.classList.remove('in');
            }
        });
    }
}
