import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['pane', 'tab'];

    change(ev) {
        this.paning(ev.target);
    }

    paning(target) {
        this.paneTargets.forEach((tab, i) => {
            console.log(target.dataset.tab);
            if (i == parseInt(target.dataset.tab)) {
                tab.classList.add('in');
                tab.classList.remove('hide');
            } else {
                tab.classList.add('hide');
                tab.classList.remove('in');
            }
        });
        console.log(this.tabTargets);
        this.tabTargets.forEach((tab, i) => {
            tab.classList.remove('active');
        });
        console.log(target);
        target.parentElement.classList.add('active');
    }

    connect() {
        this.paning(this.tabTargets[0].children[0]);
    }
}
