import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['hide', 'trigger'];

    connect() {
        this.hideOrShow(this.triggerTarget.value);
    }

    toggle(e) {
        this.hideOrShow(e.target.value);
    }

    hideOrShow(check) {
        if (
            check == this.data.get('trigger') &&
            this.hideTarget.classList.contains('hide')
        ) {
            this.hideTarget.classList.remove('hide');
            this.hideTarget.classList.add('in');
        } else {
            this.hideTarget.classList.remove('in');
            this.hideTarget.classList.add('hide');
            var ips = this.hideTarget.querySelectorAll(
                '.control-group .controls textarea,input'
            );
            var i;
            for (i = 0; i < ips.length; i++) {
                ips[i].value = '';
            }
        }
    }
}
