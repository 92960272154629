import { Controller } from 'stimulus';
// const axios = require('axios')
import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

export default class extends Controller {
    static targets = ['popupbtn'];

    popup(e) {
        this.toggleAllBtns();
        e.preventDefault();
        var res = confirm(this.data.get('message'));
        if (res) {
            axios(this.data.get('url'), {
                method: 'PUT',
                data: { buttonid: this.popupbtnTarget.id },
            })
                .then(function (json) {
                    var data = json.data.data;
                    console.log(data);
                    if (data.ok) {
                        if (data.user == 'client') {
                            window.location.href =
                                "/client/conferences/?msg='Thank you for accepting one of the quotations. We will be in touch.'";
                        } else {
                            window.location.href =
                                "/admin/conferences/?msg='Thank you for accepting one of the quotations. We will be in touch.'";
                        }
                    } else {
                        var modal = document.getElementById('myModal');
                        modal.innerHTML = data.form;
                        modal.setAttribute('style', 'display: block;');
                        modal.classList.add('in');
                        var div =
                            document.getElementsByClassName('modal-footer')[0];
                        div.setAttribute(
                            'data-confirm-hotel-buttonid',
                            data.buttonid
                        );
                        var hed =
                            document.getElementsByClassName('modal-header')[0];
                        hed.setAttribute(
                            'data-confirm-hotel-buttonid',
                            data.buttonid
                        );
                        document.body.innerHTML +=
                            '<div class="modal-backdrop fade in"></div>';
                    }
                })
                .catch(function (error) {
                    alert(error);
                    console.log(error);
                });
        } else {
            this.toggleAllBtns();
        }
    }

    closeModal() {
        var modal = document.getElementById('myModal');
        modal.setAttribute('style', 'display: none;');
        modal.classList.remove('in');
        var bd = document.getElementsByClassName('modal-backdrop')[0];
        bd.classList.add('hide');
        bd.classList.remove('in');
        this.toggleAllBtns();
        modal.innerHTML = '';
    }

    toggleAllBtns() {
        this.application.controllers.forEach(function (c) {
            console.log(c.popupbtnTarget);
            if (c.popupbtnTarget.disabled == true) {
                c.popupbtnTarget.disabled = false;
            } else {
                c.popupbtnTarget.disabled = true;
            }
        });
    }
}
