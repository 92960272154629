import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['table'];

    dragstart(event) {
        event.dataTransfer.setData(
            'application/drag-key',
            event.target.getAttribute('data-row-id')
        );
        event.dataTransfer.effectAllowed = 'move';
        document.body.style.cursor = 'move';
    }
    //make sure browser doesn't do anything
    dragover(event) {
        event.preventDefault();
        return true;
    }

    dragenter(event) {
        event.preventDefault();
    }

    drop(event) {
        var data = event.dataTransfer.getData('application/drag-key');
        var dropTarget = event.target;
        if (dropTarget.tagName == 'TD') {
            dropTarget = dropTarget.parentNode;
            const draggedItem = this.element.querySelector(
                `[data-row-id='${data}']`
            );
            const positionComparison =
                dropTarget.compareDocumentPosition(draggedItem);
            if (positionComparison & 4) {
                dropTarget.insertAdjacentElement('beforebegin', draggedItem);
            } else if (positionComparison & 2) {
                dropTarget.insertAdjacentElement('afterend', draggedItem);
            }
        }

        event.preventDefault();
        document.body.style.cursor = 'default';
    }

    dragend(event) {
        var url = this.data.get('url');
        var table = this.tableTarget;
        var data = [];
        var rows = table.rows;

        console.log(table.rows);
        for (var row of table.rows) {
            if (row.dataset.rowId == null) {
                continue;
            }
            data.push(row.dataset.rowId);
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute('content'),
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'same-origin',
            body: JSON.stringify({ data: data }),
        });
    }
}
